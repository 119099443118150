import React, {useState} from 'react'
import { Grid, Icon, InputBase, makeStyles, Popover, Typography, withStyles } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import cx from 'classnames'
import { DatePicker, MuiPickersUtilsProvider, DateTimePicker  } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
//import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'8px 16px',
        paddingTop:8,
        borderRadius:20,
        cursor:'pointer',
        '&:hover':{
            cursor:'pointer'
        }
    },
    icon:{
        color:blueGrey[500],

    },
    subtitle:{
        paddingLeft:0,
        paddingBottom:0,
        color:theme.palette.primary.main
    },
    popper:{
        marginTop:8,
        padding:'16px 8px',
        paddingLeft:0,
        borderRadius:32,
        width:200,
        boxSizing:'border-box',
        overflow:'hidden'
    },
    container:{
        maxHeight:250,
        //background:'red',
        overflowX:'hidden',
        overflowY:'auto',
        
    },
    item:{
        fontFamily: theme.typography.fontFamily,
        padding:"12px 16px",
        borderTop:`1px solid ${grey[200]}`,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    selected:{
        fontWeight:500
    }
}))

const FilterDate = props => {
    const classes = useStyles()
    const {id, label, value, onChange, shouldDisableDate} = props

    const onInnerChange = (_data) => {
        onChange(_data.format())
    }

    return(
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div>
                <div className={classes.root} >
                    <Grid container direction='column'>
                        <Grid item >
                            <Typography variant='caption' className={classes.subtitle}>{label}</Typography>
                        </Grid>
                        <Grid item >
                            <DateTimePicker shouldDisableDate={shouldDisableDate} autoOk value={value} onChange={onInnerChange} InputProps={{disableUnderline:true}} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default FilterDate
