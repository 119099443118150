import { createMuiTheme } from "@material-ui/core";
import { brown } from "@material-ui/core/colors";
import { blueGrey, lightBlue } from "@material-ui/core/colors";

export const theme = createMuiTheme({
    palette: {
      primary: { main: '#A31E0D' }, //#1A237E //0847a7
      secondary: { main: brown[900] },  //#2196f3 //4466f2
      text:{
        primary:blueGrey[900],
        secondary:blueGrey[500],
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily:[
        'Montserrat',
        'Work Sans',
      ].join(','),
    },
    overrides:{
      MuiTypography:{
        h1:{
          fontSize:'4.5rem',
          fontWeight:500
        },
        h2:{
          fontSize:'3.75rem',
        },
        h3:{
          fontSize:'3rem',
        },
        h4:{
          fontWeight:500,
          fontSize:'2.250rem',
        },
        h5:{
            fontSize:'1.875rem',
            fontWeight:500
        },
        h6:{
          fontSize:'1.5rem',
          fontWeight:500,
        },
        subtitle1:{
          fontSize:'1.125rem',
          fontWeight:500,
        },
        subtitle2:{
          fontSize:'0.875rem',
          fontWeight:500,
        },
        body1:{
            fontSize:'1.125rem'
        },
        body2:{
            fontSize:'0.875rem'
        },
        button:{
          fontSize:'1.125rem'
        },
      }
    }
});