import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import SimpleCard from '../../../components/Structure/Cards/SimpleCard';
import device_icon from '../../../assets/machines/water-pump-1.jpg'
import { nodata } from '../../../config';
import { green, red } from '@material-ui/core/colors';
import VerticalData from './VerticalData';
import energy_icon from '../../../assets/icons/energy.png'
import power_icon from '../../../assets/icons/electric-tower.png'

import { onGetFormatNumber } from '../../../shared/utility';

const PumpCard = ({title, icon,
    pump_1_L1_L2_voltage, pump_1_L2_L3_voltage, pump_1_L3_L1_voltage,
    pump_1_T1_current, pump_1_T2_current, pump_1_T3_current,
    pump_1_power, pump_1_power_factor, pump_1_operating, pump_1_failure
}) => {

    const classes = useStyles()

    let L1_L2_voltage = nodata
    let L2_L3_voltage = nodata
    let L3_L1_voltage = nodata
    let T1_current = nodata
    let T2_current = nodata
    let T3_current = nodata
    let power = nodata
    let factor_power = nodata
    let failure = nodata
    let operating =nodata


    if(pump_1_L1_L2_voltage) L1_L2_voltage = pump_1_L1_L2_voltage.value !== null && pump_1_L1_L2_voltage.value !== undefined ? `${onGetFormatNumber(pump_1_L1_L2_voltage.value, pump_1_L1_L2_voltage.unit)}` : nodata
    if(pump_1_L2_L3_voltage) L2_L3_voltage = pump_1_L2_L3_voltage.value !== null && pump_1_L2_L3_voltage.value !== undefined ? `${onGetFormatNumber(pump_1_L2_L3_voltage.value, pump_1_L2_L3_voltage.unit)}` : nodata
    if(pump_1_L3_L1_voltage) L3_L1_voltage = pump_1_L3_L1_voltage.value !== null && pump_1_L3_L1_voltage.value !== undefined ? `${onGetFormatNumber(pump_1_L3_L1_voltage.value, pump_1_L3_L1_voltage.unit)}` : nodata
    if(pump_1_T1_current) T1_current = pump_1_T1_current.value !== null && pump_1_T1_current.value !== undefined ? `${onGetFormatNumber(pump_1_T1_current.value, pump_1_T1_current.unit)}` : nodata
    if(pump_1_T2_current) T2_current = pump_1_T2_current.value !== null && pump_1_T2_current.value !== undefined ? `${onGetFormatNumber(pump_1_T2_current.value, pump_1_T2_current.unit)}` : nodata
    if(pump_1_T3_current) T3_current = pump_1_T3_current.value !== null && pump_1_T3_current.value !== undefined ? `${onGetFormatNumber(pump_1_T3_current.value, pump_1_T3_current.unit)}` : nodata
    if(pump_1_power) power = pump_1_power.value !== null && pump_1_power.value !== undefined ? `${onGetFormatNumber(pump_1_power.value, pump_1_power.unit)}` : nodata
    if(pump_1_power_factor) factor_power = pump_1_power_factor.value !== null && pump_1_power_factor.value !== undefined ? `${onGetFormatNumber(pump_1_power_factor.value, pump_1_power_factor.unit)}` : nodata

    if(pump_1_failure) failure = pump_1_failure.value ? 'Normal' : 'Error'
    if(pump_1_operating) operating = pump_1_operating.value ? 'Normal' : 'Error'


    return ( 
        <SimpleCard>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' color='primary'>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <div>
                                <img src={icon ? icon : device_icon} alt='' className={classes.device} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={4}>
                                {/******************************** ENERGY SOURCES  ******************************* */}
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2' color='primary'>GENERAL INFORMATION</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Power'
                                                        value={power}
                                                        icon={power_icon}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Power factor'
                                                        value={factor_power}
                                                        icon={power_icon}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Failure'
                                                        value={failure}
                                                        color={failure === 'Normal' ? green[700] : red[700]}
                                                        type='status'
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Operating'
                                                        value={operating}
                                                        color={operating === 'Normal' ? green[700] : red[700]}
                                                        type='status'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/******************************** VOLTAGE SOURCES  ******************************* */}
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2' color='primary'>VOLTAGE SOURCES</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Voltage L1-L2'
                                                        value={L1_L2_voltage}
                                                        icon={energy_icon}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Voltage L2-L3'
                                                        value={L2_L3_voltage}
                                                        icon={energy_icon}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Voltage L3-L1'
                                                        value={L3_L1_voltage}
                                                        icon={energy_icon}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/******************************** VOLTAGE SOURCES  ******************************* */}
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2' color='primary'>CURRENT SOURCES</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Current T1'
                                                        value={T1_current}
                                                        icon={energy_icon}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Current T2'
                                                        value={T2_current}
                                                        icon={energy_icon}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <VerticalData 
                                                        label='Current T3'
                                                        value={T3_current}
                                                        icon={energy_icon}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/******************************** VOLTAGE SOURCES  ******************************* */}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}

const useStyles = makeStyles(theme => ({
    device:{
        width:'100%'
    }
}))
 
export default PumpCard;

