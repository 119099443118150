import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import InputText from '../../../../../components/Forms/InputForm';
import { isFormValid, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from '../../../../../shared/utility';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import { mainServer } from '../../../../../config';

const DeviceForm = ({ip_address, device, onUpdateData}) => {

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if(device){
            const _form = onInitForm(form,device)
            
            setForm(_form)
        }
    }, [device])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async() => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        let data2send = onGetSelectedFormData(form, device)

        try {
            console.log(data2send)
            setLoading(true)
            await mainServer.patch(`${ip_address}/device/${device._id}`, data2send)
            const response = await mainServer.get(`${ip_address}/device/${device._id}`)
            onUpdateData(response.data.data.device)
        } catch (error) {
            console.log(error)
        }

        setLoading(false)

        

    }

    return ( 
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputText data={form.name} onChange={onChange}/>
                </Grid>
                <Grid item xs={12}>
                    <InputText data={form.identifier} onChange={onChange}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <RoundedButton loading={loading} onClick={onSubmit} fullWidth>Save</RoundedButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
     );
}
 
const useStyles = makeStyles(theme => ({
    root:{
        margin:'16px 0px'
    }
}))

export default DeviceForm;

const formData = {
    name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'name',
            type:'text',
            fullWidth: true,
            label:'Name',
            placeholder:'Device name...'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    identifier:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'identifier',
            type:'text',
            fullWidth: true,
            label:'Identifier',
            placeholder:'Identifier...'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
}