import React, { useState, useEffect } from 'react';
import { Card, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import FilterDate from '../components/FilterDate';
import moment from 'moment-timezone'
import { connect } from 'react-redux';
import { mainServer } from '../../../config';
import FilterButton from '../components/FilterButton';
import { catalogs } from '../../../texts/esp/catalogs';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
  } from 'recharts';
import { teal } from '@material-ui/core/colors';

const HistoricalDataView = ({device_id, ip_address}) => {

    const classes = useStyles()
    const [sending, setSending] = useState(false)
    const [startdate, setStartDate] = useState(moment().startOf('m').format())
    const [enddate, setEndDate] = useState(moment().endOf('d').format())
    const [register, setRegister] = useState('pump_1_L1_L2_voltage')
    const [domain, setDomain] = useState([0,600])
    const [unit, setUnit] = useState('V')
    const [records, setRecords] = useState([])
    const [points, setPoints] = useState([])
    const [title, setTitle] = useState('Voltage L1-L2 (P1)')

    useEffect(() => {
        const initModule = async() => {
            try {
                setSending(true)
                const params = {
                    start:moment(startdate).utc().format(),
                    end:moment(enddate).utc().format(),
                    limit:300,
                    offset:0,
                }
                //console.log(params)
                const response = await mainServer.get(`${ip_address}/device/${device_id}/record`, {params:params})
                const records = response.data.data.record
                setRecords(records)
                const filter_data = onGetPoints(records, register)
                setPoints(filter_data)
            } catch (error) {
                
            }
            setSending(false)
        }
        if(ip_address) initModule()
    }, [startdate, enddate, ip_address])

    const onChangeRegister = (nkey) => {
        const reg_data = catalogs.modbus_regs.find(el => el.value === nkey)
        setRegister(nkey)
        setDomain(reg_data.domain)
        setUnit(reg_data.unit)
        setTitle(reg_data.label)
        const filter_data = onGetPoints(records, nkey)
        setPoints(filter_data)
    }

    const futureDisabled = (date) => {
        return date.diff(moment(startdate)) < 0
    }

    const pastDisabled = (date) => {
        return date.diff(moment(enddate)) > 0
    }

    const onGetPoints = (data, key) => {
        if(!data) return []
        if(!key) return []
        let results = []
        data.forEach(item => {
            const currentTime = moment(item.timestamp.value)
            const currentPoint = item[key].value
            results.push({
                time:currentTime.format('DD MMM, HH:mm'),
                value:currentPoint
            })
        })
        return results
    }

    return ( 
        <LoadingContainer>
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FilterDate label='Start date' value={startdate} onChange={(val) => setStartDate(val)} 
                                shouldDisableDate={pastDisabled}/>
                            </Grid>
                            <Grid item>
                                <FilterDate label='End date'  value={enddate}  onChange={(val) => setEndDate(val)} 
                                shouldDisableDate={futureDisabled}/>
                            </Grid>
                            <Grid item>
                                <FilterButton label='Register type' selected={register} options={catalogs.modbus_regs} onChange={onChangeRegister} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {sending ? <LinearProgress />: null}
                        <Card className={classes.card}>
                            <Typography color='primary' variant='subtitle1' align='center'>{title}</Typography>
                            <div style={{width:'100%', height:400}}>
                                <ResponsiveContainer>
                                    <AreaChart data={points}
                                        margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                    
                                    <XAxis label={{value:'Time(s)', fontWeight:500}}  dataKey="time" tick={{fontSize:0}} interval={50} angle={0}/>
                                    <YAxis label={{ value: `Data ${unit ? `(${unit})` : ''}`, angle: -90, position: 'insideLeft', fontWeight:500}} domain={domain} tick={{fontSize:14, fontWeight:500}}/>
                                    <Tooltip/>
                                    <Area type='monotone' dataKey='value' stroke={teal[700]} fill={teal[700]}  />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </Card>
                        
                    </Grid>
                </Grid>
                
            </div>
        </LoadingContainer>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        margin:'24px 0px'
    },
    card:{
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:16, 
        borderRadius:16,
    }
}))

const mapStateToProps = state => {
    return {
        ip_address:state.ip_address,
    }
}
 
export default connect(mapStateToProps)(HistoricalDataView);