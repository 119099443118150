export const catalogs = {
    controller_regs:[
        {domain:[0,300], unit:'pa', value:'universal_input_1', label:'Sensor de presión (0-250 pa)'},
        {domain:[0,25], unit:'mm/s', value:'universal_input_2', label:'Vibración (0-25 mm/s)'},
        {domain:[0,105], unit:'°C', value:'universal_input_3', label:'Temperatura A (0-100°C)'},
        {domain:[0,105], unit:'°C', value:'universal_input_4', label:'Temperatura B (0-100°C)'},
    ],
    modbus_regs:[
        {domain:[0,1.5], unit:'', value:'board_local_command', label:'Local operation'},
        {domain:[0,1.5], unit:'', value:'board_remote_command', label:'Remote operation'},
        {domain:[0,1.5], unit:'', value:'pump_1_operating', label:'Operating - Pump 1'},
        {domain:[0,1.5], unit:'', value:'pump_1_failure', label:'Failure - Pump 1'},
        {domain:[0,1.5], unit:'', value:'pump_2_operating', label:'Operating - Pump 2'},
        {domain:[0,1.5], unit:'', value:'pump_2_failure', label:'Failure - Pump 2'},
        {domain:[0,1.5], unit:'', value:'reserve_1', label:'Reserve 1'},
        {domain:[0,1.5], unit:'', value:'reserve_2', label:'Reserve 2'},
        {domain:[0,1.5], unit:'', value:'gully_emergency_level', label:'Gully Emergency Level'},
        {domain:[0,1.5], unit:'', value:'gully_pump_protection_level', label:'Gully Pump Protection Level'},
        {domain:[0,600], unit:'V', value:'pump_1_L1_L2_voltage', label:'Voltage L1-L2 (P1)'},
        {domain:[0,600], unit:'V', value:'pump_1_L2_L3_voltage', label:'Voltage L2-L3 (P1)'},
        {domain:[0,600], unit:'V', value:'pump_1_L3_L1_voltage', label:'Voltage L3-L1 (P1)'},
        {domain:[0,600], unit:'A', value:'pump_1_T1_current', label:'Current T1 (P1)'},
        {domain:[0,600], unit:'A', value:'pump_1_T2_current', label:'Current T2 (P1)'},
        {domain:[0,600], unit:'A', value:'pump_1_T3_current', label:'Current T3 (P1)'},
        {domain:[0,500], unit:'kW', value:'pump_1_power', label:'Power (P1)'},
        {domain:[0,1.1], unit:'%', value:'pump_1_power_factor', label:'Power Factor (P2)'},
        {domain:[0,600], unit:'V', value:'pump_2_L1_L2_voltage', label:'Voltage L1-L2 (P2)'},
        {domain:[0,600], unit:'V', value:'pump_2_L2_L3_voltage', label:'Voltage L2-L3 (P2)'},
        {domain:[0,600], unit:'V', value:'pump_2_L3_L1_voltage', label:'Voltage L3-L1 (P2)'},
        {domain:[0,600], unit:'A', value:'pump_2_T1_current', label:'Current T1 (P2)'},
        {domain:[0,600], unit:'A', value:'pump_2_T2_current', label:'Current T2 (P2)'},
        {domain:[0,600], unit:'A', value:'pump_2_T3_current', label:'Current T3 (P2)'},
        {domain:[0,500], unit:'KW', value:'pump_2_power', label:'Power (P2)'},
        {domain:[0,1.1], unit:'%', value:'pump_2_power_factor', label:'Power Factor (P2)'},
        {domain:[0,11], unit:'m', value:'gully_level', label:'Gully Level'}
    ]
}