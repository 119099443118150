import React, { useState } from 'react';
import { Avatar, Card, ClickAwayListener, Grid, Grow, Icon, makeStyles, Popper, Typography } from '@material-ui/core';
import nouser from '../../assets/nouser.png'
import { nodata } from '../../config';
import { onGetFullname } from '../../shared/utility';
import { blueGrey } from '@material-ui/core/colors';

const UserButton = ({user, onItemSelected}) => {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const onInnerItemSelected = (url) => {
        setAnchorEl(null)
        onItemSelected(url)
    }

    const buttonContent = <Card className={classes.container}>
        <div className={classes.menu}>
            <Grid container>
                <Grid item xs={12}>
                    <OptionComponent icon='login' label='Cerrar Sesión' id='logout' onItemSelected={onInnerItemSelected}/>
                </Grid>
            </Grid>
        </div>
    </Card>

    return ( 
        <div>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-end' disablePortal >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                        {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <Card className={classes.card} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                        <Avatar src={nouser} alt='' className={classes.avatar}/>
                    </Grid>
                    <Grid item>
                        <Grid container direction='column'>
                            <Grid item>
                                <Typography variant='subtitle2'>{user ? onGetFullname(user.first_name, user.last_name) : nodata}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2' color='textSecondary'>
                                    {user ? user.user_type : nodata}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Icon>expand_more</Icon>
                    </Grid>
                </Grid>
            </Card>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    card:{
        borderRadius:24,
        paddingRight:8,
        cursor:'pointer',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',

    },
    avatar:{
        width:48,
        height:48
    },
    container:{
        marginTop:8,
        borderRadius:16
    }
}))
 
export default UserButton;

const useStyles4Option = makeStyles(theme=>({
    root:{
        padding:theme.spacing(1),
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            background:blueGrey[50],
            transition:theme.transitions.create(['background'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    icon:{color:theme.palette.primary.main}
}))

const OptionComponent = props => {

    const {id, icon, label, onItemSelected} = props
    const classes = useStyles4Option()

    return(
        <div className={classes.root} onClick={() => onItemSelected(id)}>
            <Grid container spacing={1} wrap='nowrap' alignItems='center'>
                <Grid item><Icon className={classes.icon}>{icon}</Icon></Grid>
                <Grid item><Typography variant='body2' style={{color:'bluegrey'}}>{label}</Typography></Grid>
            </Grid>
        </div>
    )
}