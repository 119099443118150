import React, {useState, useEffect, useCallback} from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import SimpleCard from '../../components/Structure/Cards/SimpleCard';
import InputText from '../../components/Forms/InputForm';
import RoundedButton from '../../components/Actions/RoundedButton';
import logo from '../../assets/logo.png'
import NetworkCard from './components/NetworkCard';
import { actionTypes } from '../../store/actions';
import { connect } from 'react-redux';
import { CONFIG_SETTINGS, mainServer } from '../../config';
import { onGetFormData, onSetErrorsToForm } from '../../shared/utility';
import { isFormValid } from '../../components/Forms/customFunctions';
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer';

const SignInView = ({ip_address, history, onUpdateURL}) => {

    const classes = useStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const initModule = async() => {
            const isauth = localStorage.getItem('auth')
            if(isauth){
                history.push('/')
                return
            }
            if(CONFIG_SETTINGS.MODE === 'local'){
                console.log('Get local ip address')
                console.log(window.location.hostname)
                const _url = `http://${window.location.hostname}:9090/api`
                await localStorage.setItem('apiurl', _url)
                onUpdateURL(_url)
            }else{
                const _url = `https://veea00002-trionixtech.pagekite.me/api`
                await localStorage.setItem('apiurl', _url)
                onUpdateURL(_url)
            }
        }
        initModule()
    },[])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async() => {

        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        
        if(!ip_address){
            setError('There is no ip address, please update with a valid one.')
            return
        }
        
        let data2send = onGetFormData(form)

        if(CONFIG_SETTINGS.DASH_USERNAME !== data2send.username 
            || CONFIG_SETTINGS.DASH_USERNAME !== data2send.username){
                setError('Invalid username or password')
                return
        }

        await localStorage.setItem('auth', 'true');
        history.push('/dashboard/60aaec7c9224530019ae6128')
        
    }



    

    return ( 
        <div className={classes.root}>
            <div className={classes.network}>
                <NetworkCard />
            </div>
            <LoadingContainer error={error} onCloseError={() => setError(null)}>
                <div className={classes.container}>
                    <div className={classes.logo_container}>
                        <img src={logo} alt='' className={classes.logo} />
                    </div>
                    <SimpleCard>
                        <div className={classes.subcontainer}>                        
                            <Typography align='center' variant='h6' color='primary' className={classes.title}>
                                ACCESO
                            </Typography>
                            <Typography align='center' variant='subtitle1' color='secondary' >
                                Ventiladores inductancia
                            </Typography>
                            <div className={classes.form}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <InputText data={form.username} onChange={onChange}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputText data={form.password} onChange={onChange}/>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.action}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <RoundedButton  onClick={onSubmit} fullWidth>Access</RoundedButton>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </SimpleCard>
                </div>
            </LoadingContainer>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.main,
        width:'100%',
        height:'100vh',
        overflowY:'auto'
    },
    container:{
        width:450,
        position:'absolute',
        zIndex:1,
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            boxSizing:'border-box',
            padding:8
        }
    },
    subcontainer:{
        padding:'40px 0px',
        position:'relative'
    },
    logo_container:{
        position:'absolute',
        width:80, height:80,
        background:'white', zIndex:1,
        borderRadius:'50%',
        top:0, left:'50%',
        transform:'translate(-50%, -50%)',
        boxShadow:'0 10px 24px 0 rgb(82 91 115 / 12%)',
        boxSizing:'border-box',
        padding:8
    },
    form:{
        marginTop:40
    },
    action:{
        marginTop:40
    },
    title:{
        letterSpacing:5,
        fontWeight:'bold'
    },
    logo:{
        width:'100%',
        height:'100%',
        objectFit:'contained'
    },
    network:{
        position:'absolute',
        right:32,
        top:32
    }
}))

const mapStateToProps = state => {
    return {
        ip_address: state.ip_address
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateURL: (ip_address) => dispatch({type:actionTypes.SYS_UPDATE_IP_ADDRESS, ip_address})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(SignInView);

const formData = {
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
            label:'User',
            placeholder:'Set the username'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password',
            type:'password',
            fullWidth: true,
            label:'Password',
            placeholder:'Password'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
}