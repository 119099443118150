import React from 'react'
import { TextField } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

const InputForm = props => {

    const {data, onChange, imgIcon} = props

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited} = data

    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''


    return(
        <div>
            <TextField  value={value} variant='outlined'
            error={isError} {...interConfig} onChange={onInnerChange} 
            InputProps={
                {
                    endAdornment:imgIcon
                }}
            />
        </div>
    )
}

export default InputForm