import axios from 'axios'

export const nodata = '-'

export const CONFIG_SETTINGS = {
    MODE:'cloud',//'local',
    API_USERNAME:process.env.REACT_APP_API_USERNAME,
    API_PASSWORD:process.env.REACT_APP_API_PASSWORD,
    API_BEARER:`Bearer ${btoa(process.env.REACT_APP_API_USERNAME +':'+ process.env.REACT_APP_API_PASSWORD)}`,
    DASH_USERNAME:process.env.REACT_APP_DASH_USERNAME,
    DASH_PASSWORD:process.env.REACT_APP_PASSWORD
}


export const mainServer = axios.create({
    baseURL: "",
    headers: {
        'Content-Type': 'application/json',
        'Authorization':`Basic ${btoa(`${CONFIG_SETTINGS.API_USERNAME}:${CONFIG_SETTINGS.API_PASSWORD}`)}`
    },
})