import logo from '../../assets/logo.png'

const defaultDevice = '60aaec7c9224530019ae6128'

export const translations = {
    sidebar:{
        logo:logo,
        title:'Ventilación Inductancia',
        list:[
            /*{
                name:'Dashboard',
                path:'/modbus',
                icon:'dashboard',
            },*/
            {
                name:'Lab',
                path:`/dashboard/${defaultDevice}`,
                icon:'biotech',
            },
            {
                name:'Dispositivos',
                path:'/devices',
                icon:'device_hub',
            },
            /*{
                name:'Locations',
                path:'/locations',
                icon:'room',
            }*/
        ]
    },
    topbar:{
        titles:[
            {
                url:'/',
                title:'Welcome!'
            },
            {
                url:'/devices',
                title:'Devices'
            },
            {
                url:'/locations',
                title:'Locations'
            },
        ]
    }
}