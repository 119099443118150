import React, { useState, useEffect } from 'react';
import { makeStyles, Table } from '@material-ui/core';
import Page from '../../components/Structure/Layouts/Page';
import SimpleTable from './components/SimpleTable';
import { mainServer } from '../../config';
import { connect } from 'react-redux';
import DeviceDrawer from './components/DeviceDrawer/DeviceDrawer';
import { actionTypes } from '../../store/actions';

const DevicesView = ({ip_address, onUpdateBlur, history}) => {

    const classes = useStyles()
    const [devices, setDevices] = useState([])
    const [device_id, setDeviceID] = useState(null)
    const [drawer, setDrawer] = useState(false)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [table_filter, setTableFilter] = useState({limit:10,offset:0})

    useEffect(() => {
        const initModule = async() => {
            try {
                setLoading(true)
                const params = {...table_filter}
                const response = await mainServer.get(`${ip_address}/device/list`, {params:params})
                setDevices(response.data.data.devices)
                setTotal(response.data.data.device_count)
            } catch (error) {
                console.log('There was an error')
            }
            setLoading(false)
            
        }
        if(ip_address) initModule()
    },[ip_address])

    const onCloseDeviceDrawer = () => {
        setDeviceID(null)
        setDrawer(false)
        onUpdateBlur(false)
    }

    const onSelectedItem = (data) => {
        setDeviceID(data._id)
        setDrawer(true)
        onUpdateBlur(true)
    }

    const onUpdateCompleted = async() => {
        try {
            //setLoading(true)
            const params = {...table_filter}
            const response = await mainServer.get(`${ip_address}/device/list`, {params:params})
            setDevices(response.data.data.devices)
            setTotal(response.data.data.device_count)
        } catch (error) {
            console.log('There was an error')
        }
        //setLoading(false)
    }

    //if(devices && devices.length) console.log(devices)

    return ( 
        <Page blur>
            <DeviceDrawer history={history} device_id={device_id} open={drawer} ip_address={ip_address} 
            onClose={onCloseDeviceDrawer} onUpdateCompleted={onUpdateCompleted}/>
            <SimpleTable 
                header={header} data={devices} total={total} loading={loading}
                table_filter={table_filter}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
            />
        </Page>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    }
}))

const mapStateToProps = state => {
    return {
        ip_address:state.ip_address,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateBlur: (blur) => dispatch({type:actionTypes.SYS_UPDATE_BLUR, blur})
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(DevicesView);

const header = [
    {id:1, label:'Name'},
    {id:2, label:'Identifier'},
    {id:3, label:'EUI'},
    {id:4, label:'Type'},
    {id:5, label:'Last report'},
    {id:6, label:'Status'}
]