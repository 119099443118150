import React from 'react'
import { CircularProgress, Grid, makeStyles, Snackbar, Typography } from '@material-ui/core'
import { blue, green } from '@material-ui/core/colors'
import ErrorModal from '../../Modals/ErrorModal'

const LoadingContainer = ({loading, children, sending, error, onCloseError}) => {
    const classes = useStyles()

    const sendingContent = <Snackbar  ContentProps={{className:classes.sending}} open={Boolean(sending)} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><CircularProgress size={18} style={{color:'white'}}/></Grid>
        <Grid item><Typography style={{color:'white'}}>Descargando...</Typography></Grid>
    </Grid>}  anchorOrigin={{vertical:'bottom', horizontal:'left'}}/>

    const errorModal = <ErrorModal open={Boolean(error)} message={error} onClose={onCloseError}/>


    return(
        <div>
            {sendingContent}
            {errorModal}
            {loading ? (
                <div style={{padding:32, minHeight:'100vh'}}>
                    <Grid container justify='center'><Grid item><CircularProgress /></Grid></Grid>
                </div>
            ) : children}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:0,
        [theme.breakpoints.down('sm')]:{
            padding:16,
        }
    },
    container:{
        padding:'32px 0px'
    },
    success:{
        background:green[700]
    },
    sending:{
        background:blue[700]
    }
}))

export default LoadingContainer