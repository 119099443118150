import { Grid, LinearProgress } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import NavigationMenu from '../../components/Actions/NavigationMenu';
import Page from '../../components/Structure/Layouts/Page';
import { mainServer } from '../../config';
import DeviceCard from './components/DeviceCard';
import GeneralView from './views/GeneralView';
import HistoricalDataView from './views/HistoricalDataView';

const device_id = '60aaed8a9224530019ae6129'

const Dashboard = ({ip_address}) => {

    const [view, setView] = useState(1)
    const [device, setDevice] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [count, setCount] = useState(0)

    useEffect(() => {
        const initModule = async() => {
            try {   
                setLoading(true)
                const response = await mainServer.get(`${ip_address}/device/${device_id}`)
                setDevice(response.data.data.device)
            } catch (error) {
                
            }
            setLoading(false)
        }
        if(ip_address && device_id) initModule()
    }, [count])

    useInterval(() => {
        // Your custom logic here
        console.log(count)
        setCount(count + 1);
    }, 60000);

    let content_view = null

    if(view === 1) content_view = (<GeneralView device={device}/>)
    if(view === 2) content_view = (<HistoricalDataView device_id={device_id}/>)

    return ( 
        <Page  error={error} onCloseErrorModal={() => setError(null)}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <DeviceCard device_id={device_id} device={device} ip_address={ip_address} onDispatchError={(e) => setError(e)}/>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <NavigationMenu selected={view} menu={content.menu} onChange={(data) => setView(data)} />
                        </Grid>
                        {loading ? <Grid item xs={12}><div style={{marginTop:16}}><LinearProgress /></div></Grid> : null}
                        <Grid item xs={12}>
                            {content_view}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    );
}

const mapStateToProps = state => {
    return {
        ip_address:state.ip_address,
    }
}
 
export default connect(mapStateToProps)(Dashboard);

const content = {
    menu:[
        {id:1, label:'Overview'},
        {id:2, label:'Historic data'},
    ]
}

const useInterval = (callback, delay) => {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}