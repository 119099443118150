import React, {useState, useEffect} from 'react';
import { Grid, makeStyles, Typography, useMediaQuery, useTheme, IconButton, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UserButton from '../../../components/Actions/UserButton';
import {translations} from '../../../texts/esp/general-translations'
import { actionTypes } from '../../../store/actions';

const Topbar = ({user, history, onUpdateDrawerStatus}) => {

    const classes = useStyles()
    const content = translations.topbar

    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const onItemSelected = async(url) => {
        await localStorage.removeItem('auth')
        history.push('/signin')
    }

    const current_path = history.location.pathname
    const selected_item = content.titles.find(el => el.url === current_path)
    const title = selected_item ? selected_item.title : ''

    return ( 
        <div>
            <Grid container alignItems='center'>
                {ismobile ? (
                    <Grid item>
                        <IconButton color='primary' onClick={() => onUpdateDrawerStatus(true)}><Icon>menu</Icon></IconButton>
                    </Grid>
                ) : null}
                <Grid item xs>
                    <Typography variant='h6' color='primary' style={{fontWeight:600}}>{title}</Typography>
                </Grid>
                <Grid item>
                    <UserButton user={user} onItemSelected={onItemSelected}/>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    }
}))

const mapStateToProps = state => {
    return {
        user:state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status})
    }
}

 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)( Topbar));
