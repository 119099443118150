import { createStore } from 'redux'
import { updateObject } from '../shared/utility'
import { actionTypes } from './actions'

const initialState = {
    user:{
        first_name:'Soler',
        last_name:'Palau',
        user_type_id:1,
        user_type:'Admin',
        
    },
    ip_address:null,
    drawer_status:true,
    blur:false
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, {user:action.user})
        case actionTypes.SYS_UPDATE_IP_ADDRESS:
            return updateObject(state, {ip_address:action.ip_address})
        case actionTypes.SYS_UPDATE_DRAWER_STATUS:
            return updateObject(state, {drawer_status:action.drawer_status})
        case actionTypes.SYS_UPDATE_BLUR:
            return updateObject(state, {blur:action.blur})
        default:
            return state;

    }
}

export const store = createStore(reducer)

