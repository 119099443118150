import React, { useState, useEffect } from 'react';
import { CircularProgress, Drawer, Fade, Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import { mainServer } from '../../../../config';
import BasicInformation from './components/BasicInformation';
import DeviceForm from './components/DeviceForm';
import DeviceData from './components/DeviceData';
import { onGetDeviceData } from '../../../../shared/utility';
import { CompassCalibrationOutlined } from '@material-ui/icons';
import ControllerData from './components/ControllerData';
//60823cecc3afe67f9c2748d5

const faked_id = '60823cecc3afe67f9c2748d5'


const DeviceDrawer = ({history, device_id, ip_address, open, onClose, onUpdateCompleted}) => {

    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [device, setDevice] = useState(null)
    const [device_type, setDeviceType] = useState(1)


    useEffect(() => {
        const initModule = async() => {
            try {   
                setLoading(true)
                const response = await mainServer.get(`${ip_address}/device/${device_id}`)
                const _device = response.data.data.device
                setDevice(_device)
                const {model} = _device
                if(model && model.type === 'Controller') setDeviceType(2)
                if(model && model.type === 'Bridge') setDeviceType(1)
            } catch (error) {
                
            }
            setLoading(false)
        }
        if(ip_address && device_id) initModule()
    }, [open, device_id])

    const onGoToDashboard = (_device) => {
        console.log(_device)
        if(_device){
            const {model} = _device
            if(model && model.type === 'Controller'){
                console.log('Go to controller dashboard')
                onClose()
                history.push(`/dashboard/${_device._id}`)

            }
            if(model && model.type === 'Bridge'){
                console.log('Go to modbus dashboard')
                onClose()
                history.push(`/`)
            }
        }
    }

    const device_data = onGetDeviceData(device)

    return ( 
        
        <Drawer  open={open} anchor='right' classes={{paper:classes.paper_drawer}}
    variant='temporary' ModalProps={{BackdropProps:{invisible:true}}} onClose={onClose}>
            <div className={classes.root}>
                <IconButton onClick={onClose} style={{position:'absolute', right:16, top:16}}><Icon>close</Icon></IconButton>
                <LoadingContent loading={loading}/>
                <Fade in={!loading}>
                    <div>
                        <BasicInformation device_type={device_type} device={device} onGoToDashboard={onGoToDashboard}/>
                        <DeviceForm device={device} ip_address={ip_address} 
                        onUpdateData={(device) => {setDevice(device); onUpdateCompleted()}}/>
                        {device_type === 1 ? <DeviceData  data={device_data}/> : null }
                        {device_type === 2 ? <ControllerData  data={device_data}/> : null }
                    </div>
                </Fade>
            </div>
        </Drawer>
            
        
    );
}

const useStyles = makeStyles({
    paper_drawer: {
      borderTopLeftRadius:24,
      borderBottomLeftRadius:24,
    },
    root:{
        padding:32,
        width:350
    }
});
 
export default DeviceDrawer;

const LoadingContent = ({loading}) => {
    return(
        <div style={{position:'absolute', top:'25%', left:'50%', zIndex:1, transform:'translate(-50%,-50%)'}}>
            <Fade in={loading}>
                <div >
                    <Grid container direction='column' alignItems='center' spacing={1}>
                        <Grid item>
                            <CircularProgress size={32}/>
                        </Grid>
                        <Grid item>
                            <Typography>Loading...</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </div>
        
    )
}