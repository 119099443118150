import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import modbus_device from '../../../../../assets/devices/modus-lora.png'
import controller_device from '../../../../../assets/devices/controller.png'

import StatusData from '../../../../../components/Structure/DisplayData/StatusData';
import { nodata } from '../../../../../config';
import { onGetDateFormat, onGetDeviceData, onGetDeviceStatus, onGetDeviceType } from '../../../../../shared/utility';
import RoundedButton from '../../../../../components/Actions/RoundedButton';

const BasicInformation = ({device_type, device, onGoToDashboard}) => {

    const classes = useStyles()

    const device_data = onGetDeviceData(device)
    const device_status = onGetDeviceStatus(device_data.last_seen)

    return ( 
        <div>
            <div>
                <img src={device_type === 1 ? modbus_device : controller_device} className={classes.image} />
            </div>
            <Typography align='center' variant='subtitle1'>{device && device.name ? device.name : nodata }</Typography>
            <Typography align='center' variant='subtitle2' color='textSecondary'>{device && device.identifier ? device.identifier : nodata }</Typography>
            <Typography align='center' variant='subtitle2' color='textSecondary'>{onGetDeviceType(device)}</Typography>
            <Grid container alignItems='center' direction='column'>
                <Grid item>
                     <StatusData  variant='body2' {...device_status}
                     />
                </Grid>
            </Grid>
            <div style={{marginTop:32}}>
                <Grid container justify='center'>
                    <Grid item>
                        <div style={{marginBottom:32}}>
                            <RoundedButton color='primary' onClick={() => onGoToDashboard(device)}>Go To Dash</RoundedButton>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <HorizontalData label='EUI' value={device && device.eui ? device.eui : nodata}/>
                    </Grid>
                    <Grid item xs={12}>
                        <HorizontalData label='Last report' value={`${onGetDateFormat(device_data.last_seen, 'DD MMM, HH:mm')}h`}/>
                    </Grid>
                    <Grid item xs={12}>
                        <HorizontalData label='Signal' value={`${device_data.signal ? device_data.signal : nodata } %`}/>
                    </Grid>
                    
                </Grid>
            </div>
        </div>
     );
}
 
const useStyles = makeStyles(theme => ({
    root:{

    },
    image:{
        margin:'auto',
        display:'block',
        width:200
    }
}))


export default BasicInformation;

const HorizontalData = ({label, value}) => {
    return(
        <Grid container>
            <Grid item xs={12} md={4}>
                <Typography variant='body1' color='textSecondary'>{label}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <Typography variant='subtitle1' align='right'>{value ? value : nodata}</Typography>
            </Grid>
        </Grid>
    )
}