import React, {useState} from 'react';
import SimpleCard from '../../../components/Structure/Cards/SimpleCard';
import modbus_device from '../../../assets/devices/modus-lora.png'
import controller_device from '../../../assets/devices/controller.png'
import { Grid, makeStyles, Typography } from '@material-ui/core';
import StatusData from '../../../components/Structure/DisplayData/StatusData';
import { green, red } from '@material-ui/core/colors';
import { mainServer, nodata } from '../../../config';
import moment from 'moment-timezone';
import { onGetDeviceData, onGetDeviceStatus, onGetDeviceType } from '../../../shared/utility';
import RoundedButton from '../../../components/Actions/RoundedButton';

const DeviceCard = ({device, ip_address, device_id, onDispatchError}) => {

    const classes = useStyles()

    const [mobile, setMobile] = useState(false)
    const [phone, setPhone] = useState(false)
    const [loading, setLoading] = useState(false)

    console.log(device)

    const device_data = onGetDeviceData(device)
    const device_status = onGetDeviceStatus(device_data.last_seen)

    let isconnected = true


    const onSendEmailNotification = async() => {
        if(mobile) return
        try {
            setMobile(true)
            await mainServer.post(`${ip_address}/notification/email`, 
            {
                device_id:device_id,
                email:'cgonzalez@trionix.io'
            })
            setMobile(false)
        } catch (error) {
            console.log('Email cannot be sent')
            onDispatchError('Email cannot be sent')
            setMobile(false)
        }
    }

    const onSendMobileNotification = async() => {
        if(phone) return
        try {
            setPhone(true)
            await mainServer.post(`${ip_address}/notification/sms`, 
            {
                device_id:device_id,
                mobile:'525550593905'
            })
            setPhone(false)
        } catch (error) {
            console.log('SMS cannot be sent')
            onDispatchError('SMS cannot be sent')
            setPhone(false)
        }
    }

    return ( 
        <SimpleCard>
            <div>
                <img src={modbus_device} className={classes.image} />
            </div>
            <Typography align='center' variant='subtitle1'>{device && device.name ? device.name : nodata }</Typography>
            <Typography align='center' variant='subtitle2' color='textSecondary'>{device && device.identifier ? device.identifier : nodata }</Typography>
            <Typography align='center' variant='subtitle2' color='textSecondary'>{onGetDeviceType(device)}</Typography>
            <Grid container alignItems='center' direction='column'>
                <Grid item>
                     <StatusData  variant='body2'
                     data={isconnected ? 'Online' : 'Offline'}
                     color={isconnected ? green[700] : red[700]}
                     />
                </Grid>
            </Grid>
            <div style={{marginTop:32, marginBottom:32}}>
                <Grid container>
                    <Grid item xs={12}>
                        <HorizontalData label='EUI' value='a5652s54c51s4'/>
                    </Grid>
                    <Grid item xs={12}>
                        <HorizontalData label='Last report' value={`${moment().format('DD MMM, HH:mm')}h`}/>
                    </Grid>
                    <Grid item xs={12}>
                        <HorizontalData label='Signal' value={`88%`}/>
                    </Grid>
                </Grid>
            </div>
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <RoundedButton loading={mobile} fullWidth  color='primary' onClick={onSendEmailNotification}>Send email notification</RoundedButton>
                    </Grid>
                    <Grid item xs={12}>
                        <RoundedButton loading={phone} fullWidth onClick={onSendMobileNotification}>Send mobile notification</RoundedButton>
                    </Grid>
                </Grid>
                
            </div>
        </SimpleCard>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    image:{
        margin:'auto',
        display:'block',
        width:200
    }
}))
 
export default DeviceCard;

const HorizontalData = ({label, value}) => {
    return(
        <Grid container>
            <Grid item xs={12} md={4}>
                <Typography variant='subtitle1' color='textSecondary'>{label}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <Typography variant='subtitle1' align='right'>{value ? value : nodata}</Typography>
            </Grid>
        </Grid>
    )
}