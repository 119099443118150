import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import SimpleCard from '../../../components/Structure/Cards/SimpleCard';
import device_icon from '../../../assets/machines/electic-board.jpg'
import { nodata } from '../../../config';
import { green, red } from '@material-ui/core/colors';
import VerticalData from './VerticalData';

import water_level_icon from '../../../assets/icons/water-level.png'

import { onGetFormatNumber } from '../../../shared/utility';

const GeneralBoardCard = ({title, board_local_command, board_remote_command, gully_level}) => {

    const classes = useStyles()

    let local_comand = nodata
    let remote_command = nodata
    let glevel = nodata

    if(board_local_command) local_comand = board_local_command.value ? 'Online' : 'Offline'
    if(board_remote_command) remote_command = board_remote_command.value ? 'Online' : 'Offline'
    if(gully_level) glevel = gully_level.value !== null && gully_level.value !== undefined ? `${onGetFormatNumber(gully_level.value, gully_level.unit)}` : nodata



    return ( 
        <SimpleCard>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' color='primary'>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <div>
                                <img src={device_icon} alt='' className={classes.device} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                        label='Local operation'
                                        value={local_comand}
                                        color={local_comand === 'Online' ? green[700] : red[700]}
                                        type='status'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                        label='Remote operation'
                                        value={remote_command}
                                        color={remote_command === 'Online' ? green[700] : red[700]}
                                        type='status'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                        label='Gully level'
                                        value={glevel}
                                        icon={water_level_icon}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}

const useStyles = makeStyles(theme => ({
    device:{
        width:'100%'
    }
}))
 
export default GeneralBoardCard;

