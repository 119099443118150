import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import StatusData from '../../../components/Structure/DisplayData/StatusData';
import { nodata } from '../../../config';

const useStylesVD = makeStyles(theme => ({
    image:{
        width:36,
        height:36
    }
}))

const VerticalData = ({label, value, icon, color, type}) => {

    const classes = useStylesVD()

    let content = (
        <Grid container alignItems='center' spacing={1}>
            <Grid item>
                <img src={icon} alt='' className={classes.image}/>
            </Grid>
            <Grid item xs>
                <Typography variant='body1'>{value ? value : nodata}</Typography>
            </Grid>
        </Grid>
    )

    if(type === 'status'){
        content = <StatusData  data={value} color={color} />
    }

    return(
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='subtitle2'>{label}</Typography>
            </Grid>
            <Grid item xs={12}>
                {content}
            </Grid>
        </Grid>
    )
}

export default VerticalData