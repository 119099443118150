import React from 'react';
import {  BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import DashboardView from '../layouts/DashboardView/DashboardView';
import SignInView from '../layouts/SignInView/SignInView';

const MainRouter = ({history}) => {
    return (
        <Router history={history}>
            <Switch>
                <Route component={SignInView} path='/signin' />
                <Route path={'/'} component={DashboardView}/>
                <Redirect to='/signin'/>
            </Switch>
        </Router>
    )
}

export default MainRouter