import { makeStyles } from '@material-ui/core';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import DashboardRouter from '../../routes/dashboard-router';
import { actionTypes } from '../../store/actions';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import cx from 'classnames'
const DashboardView = ({history, onUpdateURL, blur}) => {

    const classes = useStyles()

    useEffect(() => {
        const initModule = async() => {
            const _url = await localStorage.getItem('apiurl')
            if(!_url){
                await localStorage.clear()
                onUpdateURL(null)
                history.push('/signin')
                return
            }
            onUpdateURL(_url)
        }
        initModule()
    },[])

    return ( 
        <div className={cx({
            [classes.root]:true,
            [classes.root_blur]:blur
        })}>
            <Sidebar />
            <main className={classes.main}>
                <Topbar />
                <div>
                    <DashboardRouter/>
                </div>
            </main>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:'#edf2f8',
        minHeight:'100vh',
        overflowY:'auto',
        filter:'none'

    },
    root_blur:{
        filter:'blur(3px)'
    },
    main:{
        marginLeft:150,
        padding:16,
        [theme.breakpoints.down('sm')]:{
            marginLeft:0,
        }
        
    }
}))

const mapStateToProps = state => {
    return {
        ip_address:state.ip_address,
        blur: state.blur
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateURL: (ip_address) => dispatch({type:actionTypes.SYS_UPDATE_IP_ADDRESS, ip_address})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);