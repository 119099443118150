import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { nodata } from '../../../../../config';
import { blueGrey } from '@material-ui/core/colors';

const DeviceData = ({data}) => {
    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Modbus registers</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <HorizontalData label='Local operation' value={data ? (data.board_local_command === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Remote operation' value={data ? (data.board_remote_command === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Operating - Pump 1' value={data ? (data.pump_1_operating  === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Failure - Pump 1' value={data ? (data.pump_1_failure  === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Operating - Pump 2' value={data ? (data.pump_2_operating  === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Failure - Pump 2' value={data ? (data.pump_2_failure  === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>


                        <Grid item xs={12}>
                            <HorizontalData unit='V' label='Voltage L1-L2 (P1)' value={data ? data.pump_1_L1_L2_voltage : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='V' label='Voltage L2-L3 (P1)' value={data ? data.pump_1_L2_L3_voltage : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='V' label='Voltage L3-L1 (P1)' value={data ? data.pump_1_L3_L1_voltage : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='A' label='Current T1 (P1)' value={data ? data.pump_1_T1_current : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='A' label='Current T2 (P1)' value={data ? data.pump_1_T2_current : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='A' label='Current T3 (P1)' value={data ? data.pump_1_T3_current : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='kW' label='Power (P1)' value={data ? data.pump_1_power : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='' label='Power Factor (P1)' value={data ? data.pump_1_power_factor : nodata}/>
                        </Grid>


                        <Grid item xs={12}>
                            <HorizontalData unit='V' label='Voltage L1-L2 (P2)' value={data ? data.pump_2_L1_L2_voltage : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='V' label='Voltage L2-L3 (P2)' value={data ? data.pump_2_L2_L3_voltage : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='V' label='Voltage L3-L1 (P2)' value={data ? data.pump_2_L3_L1_voltage : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='A' label='Current T1 (P2)' value={data ? data.pump_2_T1_current : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='A' label='Current T2 (P2)' value={data ? data.pump_2_T2_current : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='A' label='Current T3 (P2)' value={data ? data.pump_2_T3_current : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='kW' label='Power (P2)' value={data ? data.pump_2_power : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData unit='' label='Power Factor (P2)' value={data ? data.pump_2_power_factor : nodata}/>
                        </Grid>



                        <Grid item xs={12}>
                            <HorizontalData unit='m' label='Gully Level' value={data ? data.gully_level : nodata}/>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>

    );
}
 
export default DeviceData;

const HorizontalData = ({label, value, unit}) => {
    return(
        <div style={{borderTop:`1px solid ${blueGrey[50]}`, padding:'8px 0px'}}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Typography variant='subtitle2' color='textSecondary'>{label}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant='subtitle2' align='right'>{`${value ? value : nodata}${unit ? unit : ''}`}</Typography>
                </Grid>
            </Grid>
        </div>
        
    )
}