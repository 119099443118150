import React, {useEffect, useState} from 'react';
import { Card, Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import InputForm from '../../../components/Forms/InputForm';
import RoundedButton from '../../../components/Actions/RoundedButton';
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../shared/utility';
import { mainServer } from '../../../config';
import { actionTypes } from '../../../store/actions';
import { connect } from 'react-redux';

const NetworkCard = ({ip_address, onUpdateURL}) => {

    const classes = useStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [editable, setEditable] = useState(true)

    useEffect(() => {
        const initModule = async() => {
            const _url = await localStorage.getItem('apiurl')
            //console.log('Actualizar URL al state')
            //console.log(_url)
            onUpdateURL(_url)
        }
        initModule()
    },[])

    useEffect(() => {
        //console.log('-----API URL---')
        //console.log(ip_address)
    },[ip_address])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async() => {

        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        let data2send = onGetFormData(form)

        const url = data2send.ipaddress
        try {
            await mainServer.get(`${url}/check`)
            await localStorage.setItem('apiurl', url)
            onUpdateURL(url)
            
        } catch (error) {
            console.log(error)
        }
    }

    const onRemoveURL = async() => {
        try {
            await localStorage.removeItem('apiurl')
            onUpdateURL(null)
        } catch (error) {
            console.log(error)
        }
    }

    let content = null

    if(ip_address){
        content = (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Grid container alignItems='center' wrap='nowrap'>
                        <Grid item xs>
                            <Typography variant='subtitle2' color='textSecondary'>IP Address</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton size='small' onClick={onRemoveURL}><Icon>close</Icon></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle2'>{ip_address}</Typography>
                </Grid>
            </Grid>
        )
    }else{
        content = (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2'>IP Address</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.ipaddress} onChange={onChange}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end'>
                        <Grid item>
                            <RoundedButton onClick={onSubmit}>Guardar</RoundedButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return ( 
        <div style={{visibility:'hidden'}}>
            <Card className={classes.card}>
                {content}
            </Card>            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    card:{
        padding:'16px 16px',
        boxSizing:'border-box',
        width:350,
        borderRadius:16
    }
}))

const mapStateToProps = state => {
    return {
        ip_address:state.ip_address
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateURL: (ip_address) => dispatch({type:actionTypes.SYS_UPDATE_IP_ADDRESS, ip_address})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(NetworkCard);

const formData = {
    ipaddress:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'ipaddress',
            type:'text',
            fullWidth: true,
            label:'',
            placeholder:'http://192.168.10.11',
            helperText:'Dirección no válida'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
}