import { Grid, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import { fakedevice } from '../../../fakedata';
import { onGetDeviceData, onGetModbusGeneralData } from '../../../shared/utility';
import GeneralBoardCard from '../components/GeneralBoardCard';
import PumpCard from '../components/PumpCard';
import pumpA from '../../../assets/machines/water-pump-1.png';
import pumpB from '../../../assets/machines/water-pump-2.png';
import PumpCardB from '../components/PumpCardB';



const GeneralView = ({device}) => {

    const classes = useStyles()

    const device_data = onGetDeviceData(device)

    const general_board_data = onGetModbusGeneralData(device, 
        ['board_local_command', 'board_remote_command', 'gully_level'])
    const first_pump_data = onGetModbusGeneralData(device, 
        ['pump_1_L1_L2_voltage', 'pump_1_L2_L3_voltage', 'pump_1_L3_L1_voltage',
        'pump_1_T1_current','pump_1_T2_current','pump_1_T3_current',
        'pump_1_power','pump_1_power_factor','pump_1_operating','pump_1_failure'
    ])

    const second_pump_data = onGetModbusGeneralData(device, 
        ['pump_2_L1_L2_voltage', 'pump_2_L2_L3_voltage', 'pump_2_L3_L1_voltage',
        'pump_2_T1_current','pump_2_T2_current','pump_2_T3_current',
        'pump_2_power','pump_2_power_factor','pump_2_operating','pump_2_failure'
    ])

    return ( 
        <LoadingContainer>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <GeneralBoardCard 
                            title='Información general'
                            data={device_data}
                            {...device_data}/>
                    </Grid>
                </Grid>
            </div>
        </LoadingContainer>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'24px 0px'
    }
}))
 
export default GeneralView;