import { green, red } from '@material-ui/core/colors';
import moment from 'moment-timezone'
import { nodata } from '../config';
import { ERROR_CODES } from '../texts/esp/errorCodes';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


export const isValidDocument = (filename) => filename && filename !== "0" && filename !== 0 && filename !== ""

/*export const onGetFormatNumber = (number) => {
    let result = ''
    if(!number) return '$0'
    let temp = parseFloat(number).toFixed(0)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    result = `$ ${result}`
    return result
}*/

export const onGetFormatNumber = (number, unit, precision) => {
    if(!number) return `0 ${unit}`
    let temp = parseFloat(number).toFixed(precision !== undefined && precision !== null ? precision : 2)
    return `${temp} ${unit}`
}

export const isFormValid = (form) => {
    let errors = []
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isRequired && !form[item].isValid) errors.push(item)
        })
    }
    return errors
} 

export const onSetErrorsToForm = (form, errors) => {
    let temp = {...form}
    if(errors && errors.length) errors.forEach(item => temp[item].isVisited = true)
    return temp
}
export const onGetFormData = (form) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onGetSelectedFormData = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onInitForm = (form, origin, translations) => {
    let response = {...form}
    if(!origin) return response
    Object.keys(form).forEach(item => {
        const value = origin[item]
        if(value) response[item] = {...response[item], value:value, isVisited:true, isValid:true}
        if(translations && translations[item]) response[item].config = {...response[item].config, ...translations[item]}
    })
    return response
}

export const onCheckBooleanForm = (data) => {
    let _result = {...data}
    if(data.value){
        _result.value = true
        _result.isValid = true
    }else{
        _result.value = false
        _result.isValid = false
    }
    return _result
}

export const onCheckDateForm = (data) => {
    let momentDate = moment(data.value)
    let _result = {...data}
    if(momentDate.isValid()){
        _result.value = momentDate.format('YYYY-MM-DD')
        _result.isValid = true
    }else{
        _result.value = null
        _result.isValid = false
    }
    return _result
}

export const onGetDateFormat = (data, format) => {
    if(!data) return null
    const momentdata = moment(data)
    if(!momentdata.isValid()) return null
    if(format) return momentdata.format(format)
    return momentdata.format('YYYY-MM-DD')
}

export const onGetCatalogs = (options, keyValue, keyLabel) => {
    if(!options) return []
    return options.map(item => {
        return{
            value: item[keyValue], label:item[keyLabel] ? item[keyLabel] : ''
        }
    })
}

export const onGetEmployeesCatalogs = (options) => {
    if(!options) return []
    return options.map(item => {
        const fullname = onGetFullname(item.first_name, item.last_name)
        return{
            value: item.id_user, label:fullname
        }
    })
}

export const onGetFullname = (first_name, last_name) => {
    if(!first_name) return 'N/D'
    return `${first_name ? first_name : ''} ${last_name ? last_name : ''}`
}


export const onGetFileExtension = (filename) => filename.split('.').pop();

export const onGetUserID = () => {
    let res = null
    let localData = localStorage.getItem('data')
    if(localData && typeof localData === 'string'){
      localData = JSON.parse(localData)
      res = localData.user_id
    }
    return res
}

export const onGetErrorMessage = (error) => {
    let message = 'Hubo un error'
    if(!error) return ''
    if(error.response){
        if(error.response.data){
            if(error.response.data.error && error.response.data.error.code){
                const _error = ERROR_CODES[error.response.data.error.code]
                console.log(error.response.data.error.code)
                if(_error) message = _error
            }
            
        }
    }
    return message
}

const monthsName = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']

export const onGetMonthsCatalog = (year) => {
    let response = []
    for(let i=0;i<12;i++){
        const startDate = moment([year, i])
        const endDate = moment(startDate).endOf('month')
        response.push({
            value:i+1,
            label:monthsName[i],
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD')
        })
    }
    return response
}

export const onGetTimeDuration = (minutes) => {
    if(!minutes) return '0h'
    const _hours = Math.floor(minutes/60)
    const _minutes = minutes % 60
    return _minutes > 0 ? `${_hours}h ${Math.floor(_minutes)}m` : `${_hours}h`
}

export const onGetDecimalNumber = (number) => {
    let result = ''
    if(!number) return '0.0'
    let temp = parseFloat(number).toFixed(1)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result
}

export const onGetResponsibleObj = (responsible_id, users,img) => {
    const no_responsible = {first_name:'Sin', last_name:'Registro', image:img}
    if(!responsible_id) return no_responsible
    if(!users) return no_responsible
    const _user = users.find(el => el.id_user === responsible_id)
    if(!_user) return no_responsible
    return  _user
}

export const onUpdateDocumentList = (files, origin) => {
    if(!origin) return files
    let _files = files.map(item => {
        const document_path = origin[item.key]
        const isValid = isValidDocument(document_path)
        return {...item, status:isValid}
    })
    return _files
}

export const onCheckAPIURL = async() => {
    
}

////////////////////////////////////////  DEVICE FILTERING  //////////////////////////////////////////////////

export const onGetModbusGeneralData = (device, search_keys) => {

    let data2send = {}

    if(!search_keys) return {}
    if(!device) return {}
    if(!device.data) return {}

    const device_data = device.data

    search_keys.forEach(item => {
        const foundEl = device_data[item]
        if(foundEl){
            data2send = {
                ...data2send,
                [item]:{
                    type:foundEl.type,
                    unit:foundEl.unit,
                    value:foundEl.value,
                }
            }
        }
    })

    return data2send


}


export const onGetDeviceData = (device) => {

    let data2send = {}

    if(!device) return {}
    if(!device.data) return {}

    const current_data = device.data

    Object.keys(current_data).forEach(item => {
        data2send = {
            ...data2send,
            [item]:current_data[item].value
        }
    })

    return data2send


}

export const onGetTimeFormat = (data, format) => {
    if(!data) return  nodata
    const _data = moment(data)

    if(!_data.isValid()) return nodata
    console.log(format)
    if(format) return _data.format(format)
    console.log('Format default')
    return _data.format('DD MMM, HH:mm')
}

export const onGetDeviceType = (device) => {
    if(!device) return nodata
    if(!device.model) return nodata
    let response = ''
    const {type, name} = device.model
    if(type) response = type
    if(name) response = `${response} - ${name}`
    return response
}

export const onGetDeviceStatus = (data) => {

    let data2send = {
        data:'Offline',
        color:red[700]
    }

    if(!data) return data2send
    const current_time = moment()
    const device_time = moment(data)
    if(!device_time.isValid()) return data2send
    
    if(current_time.diff(device_time) > 5*60*1000 ) return data2send

    data2send.data = 'Online'
    data2send.color = green[700]

    return data2send
}


/// CUSTOM DEVICES DATA

const vi = 2.95; const r1 = 10000; const adcCounts = 4095; const ri=83

export const onGetResistancePoint = (value) => {
    if(value === null || value === undefined) return null
    const vx = (value / adcCounts)*vi
    const r2 = Math.abs((vx*r1)/(vi -vx)).toFixed(0)
    return r2
}

export const onGetCurrentPoint = (value) => {
    if(value === null || value === undefined) return null
    const vx = (value/adcCounts)*vi + 0.04
    //console.log(`${vx*1000}mV`)
    const current = vx/ri
    return parseFloat(current).toFixed(2)
    //return 1.5625*current*1000 - 6.25
}

export const onGetVoltagePoint = (value) => {
    if(value === null || value === undefined) return null
    const vx = (value/adcCounts)*vi + 0.04
    //console.log(`${vx*1000}mV`)
    //const current = vx/ri
    return parseFloat(vx).toFixed(4)
    //return 1.5625*current*1000 - 6.25
}

const factor = 5.1/15.1

export const onGetVoltage10Point = (value) => {
    //console.log(value)
    if(value === null || value === undefined) return null
    const vx = (value/adcCounts)*vi
    return parseFloat(vx*10/3).toFixed(2)
    //return parseFloat(current*1000).toFixed(2)
    //return 1.5625*current*1000 - 6.25
}

export const onGetPressurePoint = (value) => {
    //console.log(value)
    if(value === null || value === undefined) return null
    const vx = (value/adcCounts)*vi
    const vo = parseFloat(vx*10/3).toFixed(2)
    return parseFloat(vo*25).toFixed(2) // 25 to 250pa // 12.5 to 125pa 6.25 to 65pa
    //return parseFloat(current*1000).toFixed(2)
    //return 1.5625*current*1000 - 6.25
}

export const onGetPT100Point = (value) => {
    if(value === null || value === undefined) return null
    const vx = (value/adcCounts)*vi + 0.04
    //console.log(`${vx*1000}mV`)
    const current = vx/ri
    return parseFloat(current*6250-25).toFixed(2)
    //return parseFloat(vx*60.241-25).toFixed(2)
    //return 1.5625*current*1000 - 6.25
}

export const onGetVibrationPoint = (value) => {
    if(value === null || value === undefined) return null
    const vx = (value/adcCounts)*vi
    //console.log(vx)
    //console.log(`${vx*1000}mV`)
    //const current = vx/ri
    const vo = parseFloat(vx*10/3).toFixed(2)
    return parseFloat(vo*2.5).toFixed(2)
    //return 1.5625*current*1000 - 6.25
}

