import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import SimpleCard from '../../../components/Structure/Cards/SimpleCard';
import device_icon from '../../../assets/machines/electic-board.jpg'
import { nodata } from '../../../config';
import { green, grey, red } from '@material-ui/core/colors';

import water_level_icon from '../../../assets/icons/water-level.png'

import { onGetCurrentPoint, onGetFormatNumber, onGetPressurePoint, onGetPT100Point, onGetResistancePoint, onGetVibrationPoint, onGetVoltage10Point, onGetVoltagePoint } from '../../../shared/utility';
import VerticalData from '../../Dashboard/components/VerticalData';

const vi = 3; const r1 = 10000; const adcCounts = 4095; const ri=112.5

const GeneralBoardCard = ({title, data, board_local_command, board_remote_command, gully_level}) => {

    const classes = useStyles()

    let local_comand = nodata
    let remote_command = nodata
    let glevel = nodata

    if(board_local_command) local_comand = board_local_command.value ? 'Online' : 'Offline'
    if(board_remote_command) remote_command = board_remote_command.value ? 'Online' : 'Offline'
    if(gully_level) glevel = gully_level.value !== null && gully_level.value !== undefined ? `${onGetFormatNumber(gully_level.value, gully_level.unit)}` : nodata


    return ( 
        <SimpleCard>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' color='primary'>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <div>
                                <img src={device_icon} alt='' className={classes.device} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1'>Instrumentación</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InnerVerticalData 
                                        label='Presión diferencial  (0-250 Pa)'
                                        counts={data ? parseInt(data.universal_input_1*100) : nodata}
                                        voltage={data ? onGetVoltage10Point(data.universal_input_1*100) : nodata}
                                        pressure={data ? onGetPressurePoint(data.universal_input_1*100) : nodata}
                                        type='status'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InnerVerticalData 
                                        label='Vibración (0-25mm/s)'
                                        counts={data ? parseInt(data.universal_input_2*100) : nodata}
                                        voltage={data ? onGetVoltage10Point(data.universal_input_2*100) : nodata}
                                        vibration={data ? onGetVibrationPoint(data.universal_input_2*100) : nodata}
                                        type='status'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InnerVerticalData 
                                        label='Temperatura A (0-100°C)'
                                        counts={data ? parseInt(data.universal_input_3/100) : nodata}
                                        voltage={data ? onGetVoltagePoint(data.universal_input_3/100) : nodata}
                                        current={data ? onGetCurrentPoint(data.universal_input_3/100) : nodata}
                                        temperature={data ? onGetPT100Point(data.universal_input_3/100) : nodata}
                                        type='status'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InnerVerticalData 
                                        label='Temperatura B (0-100°C)'
                                        counts={data ? parseInt(data.universal_input_4/100) : nodata}
                                        voltage={data ? onGetVoltagePoint(data.universal_input_4/100) : nodata}
                                        current={data ? onGetCurrentPoint(data.universal_input_4/100) : nodata}
                                        temperature={data ? onGetPT100Point(data.universal_input_4/100) : nodata}
                                        type='status'
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}><div style={{marginTop:32}}/></Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>Salidas</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <VerticalData 
                                                    label='Salida A'
                                                    value={data ? (data.digital_output_1 === 1 ? 'Encendido' : 'Apagado') : nodata}
                                                    color={data ? (data.digital_output_1 === 1 ? green[700] : red[700]) : grey[300]}
                                                    type='status'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <VerticalData 
                                                    label='Salida B'
                                                    value={data ? (data.digital_output_2 === 1 ? 'Encendido' : 'Apagado') : nodata}
                                                    color={data ? (data.digital_output_2 === 1 ? green[700] : red[700]) : grey[300]}
                                                    type='status'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <VerticalData 
                                                    label='Salida C'
                                                    value={data ? (data.digital_output_3 === 1 ? 'Encendido' : 'Apagado') : nodata}
                                                    color={data ? (data.digital_output_3 === 1 ? green[700] : red[700]) : grey[300]}
                                                    type='status'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <VerticalData 
                                                    label='Salida D'
                                                    value={data ? (data.digital_output_4 === 1 ? 'Encendido' : 'Apagado') : nodata}
                                                    color={data ? (data.digital_output_4 === 1 ? green[700] : red[700]) : grey[300]}
                                                    type='status'
                                                />
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}

const useStyles = makeStyles(theme => ({
    device:{
        width:'100%'
    }
}))
 
export default GeneralBoardCard;


const useStylesVD = makeStyles(theme => ({
    image:{
        width:36,
        height:36
    }
}))

const InnerVerticalData = ({label, counts, current, voltage, resistance, temperature, vibration, pressure}) => {

    const classes = useStylesVD()

    return(
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant='subtitle2' color='primary'>{label}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    
                    {resistance !== null && resistance !== undefined ? <Grid item xs={12}>
                        <Typography variant='subtitle1' color='textPrimary'>{`${resistance ? resistance : nodata} ohms`}</Typography>
                    </Grid> : null}
                    {temperature !== null && temperature !== undefined ? <Grid item xs={12}>
                        <Typography variant='subtitle1' color='textPrimary'>{`${temperature ? temperature : nodata} °C`}</Typography>
                    </Grid> : null}
                    {vibration !== null && vibration !== undefined ? <Grid item xs={12}>
                        <Typography variant='subtitle1' color='textPrimary'>{`${vibration ? vibration : nodata} mm/s`}</Typography>
                    </Grid> : null}
                    {pressure !== null && pressure !== undefined ? <Grid item xs={12}>
                        <Typography variant='subtitle1' color='textPrimary'>{`${pressure ? pressure : nodata} pa`}</Typography>
                    </Grid> : null}
                    
                    {current !== null && current !== undefined ? <Grid item xs={12}>
                        <Typography variant='subtitle2' color='textSecondary'>{`${current ? current : nodata} mA`}</Typography>
                    </Grid> : null}
                    {voltage !== null && voltage !== undefined ? <Grid item xs={12}>
                        <Typography variant='subtitle2' color='textSecondary'>{`${voltage ?  voltage : nodata} V`}</Typography>
                    </Grid> : null}
                    {counts !== null && counts !== undefined ? <Grid item xs={12}>
                        <Typography variant='subtitle2' color='textSecondary'>{`${counts ? counts : nodata} counts`}</Typography>
                    </Grid> : null}
                </Grid>
            </Grid>
        </Grid>
    )
}



