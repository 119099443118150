import React from 'react';
import { Drawer, fade, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions';
import {translations} from '../../../texts/esp/general-translations'
import cx from 'classnames';
import { grey } from '@material-ui/core/colors';
import { withRouter } from 'react-router';

const Sidebar = ({drawer_status, history, onUpdateDrawerStatus}) => {

    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles()

    const isURLSelected = (path) => {
        return history.location.pathname.split('/')[1] === path.split('/')[1];
    }

    const onChangeView = (url) => {
        history.push(url)
    }

    const content = translations.sidebar

    return ( 
        <div>
            <Drawer open={drawer_status} onClose={() => onUpdateDrawerStatus(false)}
                variant={ismobile ? 'temporary' : 'persistent'}
                classes={{paper:classes.drawer}}
            >
                <div className={classes.container}>
                    <div className={classes.logo_container}>
                        <img src={content.logo} alt='' className={classes.logo}/>
                    </div>
                    <div>
                        <Typography variant='subtitle1' align='center' className={classes.title}>{content.title}</Typography>
                    </div>
                    <div className={classes.menu}>
                        <Grid container>
                            {content.list.map((item,key)=>{

                                const isactive = isURLSelected(item.path)

                                return(
                                    <Grid item xs={12} key={key.toString()}>
                                        <SidebarItem active={isactive} {...item} onChangeView={onChangeView}/>
                                    </Grid>
                                )
                            })} 
                        </Grid>
                        
                    </div>
                </div>
                
            </Drawer>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    drawer:{
        background:theme.palette.primary.main,
        borderTopRightRadius:24,
        borderBottomRightRadius:24,
        border:0,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    logo:{
        width:'100%',
        height:'100%',
        padding:8,
        boxSizing:'border-box',
        //objectFit:'cover',
        position:'absolute',
        top:0, left:0
    },
    logo_container:{
        width:84,
        height:84,
        margin:'auto',
        borderRadius:'50%',
        boxSizing:'border-box',
        background:'white',
        position:'relative'
    }, 
    container:{
        width:150,
        padding:'32px 0px'
    },
    title:{
        color:'white',
        marginTop:8
    },
    menu:{
        margin:'32px 0px'
    }
}))

const mapStateToProps = state => {
    return {
        drawer_status:state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));


const SidebarItem = ({active, name, path, icon, onChangeView}) => {

    const classes = useStylesItem()

    return(
        <div className={cx(classes.root,
        {[classes.active]:active
        })} onClick={() => onChangeView(path)}>
            <Grid container justify='center'>
                <Grid item>
                    <Icon>{icon}</Icon>
                </Grid>
                <Grid item xs={12}>
                    <Typography align='center' variant='body2'>{name}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

const useStylesItem = makeStyles(theme => ({
    root:{
        color:grey[300],
        padding:'16px 8px',
        '&:hover':{
            background:fade('#FFF',0.2),
            cursor:'pointer',
            transition: theme.transitions.create(['background'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        
    },
    active:{
        background:theme.palette.secondary.main,
        '&:hover':{
            background:theme.palette.secondary.main,
            cursor:'pointer'
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }
}))