import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import Dashboard from '../views/Dashboard/Dashboard'
import DevicesView from '../views/Devices/DevicesView'
import SubwayDashboard from '../views/SubwayDashboard/SubwayDashboard'

const DashboardRouter = props => {
    return(
        <Switch>
            
            <Route path='/dashboard/:id' component={SubwayDashboard} />
            <Route path='/devices' component={DevicesView} />
            <Route exact path='/modbus' component={Dashboard} />
            <Redirect  to='/modbus' />
        </Switch>
    )
}

export default DashboardRouter