import React from 'react';
import { Grid, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import ActionBar from './ActionBar';
import empty from '../../../assets/icons/cloud-storage.png'
import { grey } from '@material-ui/core/colors';
import { onGetDateFormat, onGetDeviceData, onGetDeviceStatus, onGetDeviceType, onGetTimeFormat } from '../../../shared/utility';
import StatusData from '../../../components/Structure/DisplayData/StatusData';
import { nodata } from '../../../config';
const SimpleTable = ({
    data, header, total, table_filter, onUpdateTableFilter, loading, onSelectedItem 
}) => {

    const classes = useStyles()

    return ( 
        <div>
            <div className={classes.toolbar}>
                {/****************** SPACE FOR TOOLBAR **********************/}
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs={12}><LinearProgress/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <Typography variant='subtitle2' >{item.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {_id, eui, data, model, name, identifier} = item

                                    const device_data = onGetDeviceData(item)
                                    //console.log(device_data)
                                    const device_status = onGetDeviceStatus(device_data.last_seen)
                                    //console.log(device_status)
                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{name ? name : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{identifier ? identifier : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{eui}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{onGetDeviceType(item)}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{onGetDateFormat(device_data.last_seen, 'DD MMM, HH:mm')}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'><StatusData {...device_status} variant='body2' /></Typography></TableCell>
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} table_filter={table_filter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle1' color='textSecondary'>Devices not found</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>


     );
}

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:100
    },
    filterButton:{
        background:'white',
        color:theme.palette.primary.main,
        '&:hover':{background:'white'}
    }
}))
export default SimpleTable;