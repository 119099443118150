import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { nodata } from '../../../../../config';
import { blueGrey } from '@material-ui/core/colors';
import { onGetCurrentPoint, onGetPressurePoint, onGetPT100Point, onGetResistancePoint, onGetVibrationPoint, onGetVoltage10Point } from '../../../../../shared/utility';

const ControllerData = ({data}) => {

    console.log(data)

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Modbus registers</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <HorizontalData label='Input A' unit='pa' value={data ? onGetPressurePoint(data.universal_input_1*100) : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Input B' unit='mm/s' value={data ? onGetVibrationPoint(data.universal_input_2*100) : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Input C' unit='°C' value={data ? onGetPT100Point(data.universal_input_3/100) : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Input D' unit='°C' value={data ? onGetPT100Point(data.universal_input_4/100) : nodata}/>
                        </Grid>

                        <Grid item xs={12}>
                            <HorizontalData label='Output A' value={data ? (data.digital_output_1 === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Output B' value={data ? (data.digital_output_2 === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Output C' value={data ? (data.digital_output_3  === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <HorizontalData label='Output D' value={data ? (data.digital_output_4  === 1 ? 'On' : 'Off') : nodata}/>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>
        </div>

    );
}
 
export default ControllerData;

const HorizontalData = ({label, value, unit}) => {
    return(
        <div style={{borderTop:`1px solid ${blueGrey[50]}`, padding:'8px 0px'}}>
            <Grid container >
                <Grid item xs={12} md={6}>
                    <Typography variant='subtitle2' color='textSecondary'>{label}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant='subtitle2' align='right'>{`${value ? value : nodata} ${unit ? unit : ''}`}</Typography>
                </Grid>
            </Grid>
        </div>
        
    )
}