import React from 'react'
import { Card, makeStyles } from '@material-ui/core'
import cx from 'classnames'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        borderRadius:32,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:32,
        [theme.breakpoints.down('sm')]:{
            padding:16,
        }
    },
    clickable:{
        '&:hover':{
            cursor:'pointer',
            background: grey[100]
        }
    }
}))

const SimpleCard = ({children, padding, onClick}) => {
    const classes = useStyles()

    let styles = {}
    if(padding === 'small') styles = {...styles, padding:12}

    return(
        <Card className={cx({
            [classes.root]:true,
            [classes.clickable]: onClick ? true: false
        })} style={styles} onClick={onClick} >
            {children}
        </Card>
    )
}

export default SimpleCard