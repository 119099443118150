import React from 'react'
import { makeStyles, Breadcrumbs, Link, Icon, Grid, CircularProgress, IconButton, Snackbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { blueGrey, green } from '@material-ui/core/colors'
import { withRouter } from 'react-router-dom'
import ErrorModal from '../../Modals/ErrorModal'

const useStyles = makeStyles(theme => ({
    root:{
        margin:'24px 0px',
        boxSizing:'border-box',        
    },
    container:{
        padding:0,
        //width:1100,
        //margin:'auto',
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            //paddingTop:16
        }
    },
    link:{
        color:blueGrey[500],
        fontWeight:400,
        '&:hover':{
            cursor:'pointer'
        }
    },
    selectedLink:{
        color:blueGrey[700],
        fontWeight:500
    },
    icon:{
        color:blueGrey[500],
        paddingTop:4,
        
    },
    loading:{
        height:`calc(100vh - 400px)`
    },
    success:{
        background:green[700]
    },
}))

const Page = props => {

    const classes = useStyles()
    const {children, breadcrumbs, loading, nopadding, error, onCloseErrorModal, success, onCloseSuccess} = props

    const onSelectedLink = (url) => {
        if(url) props.history.push(url)
    }

    let breadcrumbsContent = null
    if(breadcrumbs){
        breadcrumbsContent = (
            <Grid item xs={12}>
                <Breadcrumbs>
                    <Link className={classes.link} onClick={() => onSelectedLink('/')}
                    ><Icon className={classes.icon}>home</Icon></Link>
                    {breadcrumbs.map((item,key)=>{
                        return(
                            <Link key={key.toString()} 
                            onClick={() => onSelectedLink(item.url)}
                            className={clsx({
                                [classes.link]:true,
                                [classes.selectedLink]:item.selected
                            })}>
                                {item.label}
                            </Link>
                        )
                    })}
                </Breadcrumbs>
            </Grid>
        )
    }

    let contentView = children

    if(loading){
        contentView = (
            <Grid container justify='center'>
                <Grid item>
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                    
                </Grid>
            </Grid>
        )
    }

    const successContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.success}} open={Boolean(success)} onClose={onCloseSuccess}  autoHideDuration={1500} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>done</Icon></Grid>
        <Grid item><Typography style={{color:'white'}}>{success}</Typography></Grid>
    </Grid>}/>

    return(
        <div className={classes.root} style={nopadding ? {marginTop:0} : undefined}>
            <ErrorModal open={Boolean(error)} message={error} onClose={onCloseErrorModal}/>
            <div className={classes.container}>
                <Grid container spacing={2}>
                    {successContent}
                    {breadcrumbsContent}
                    <Grid item xs={12}>
                        {contentView}
                    </Grid>
                </Grid>
            </div>         
        </div>
    )
}

export default withRouter(Page)